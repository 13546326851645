import * as Tabs from '@radix-ui/react-tabs';
import DataTable from './DataTable';
import ResultsAbout from './ResultsAbout';
import Question from './Question';
import SurveyFlow from './SurveyFlow';
import { Results } from '../../../../../types';

function Results({ object }: { object: Results }) {
    const aboutTableData = Object.entries(object.data.about).map(
        ([key, value], index) => {
            return { id: index, key: key, value: value };
        }
    );

    return (
        <Tabs.Root defaultValue="responses">
            <Tabs.List
                className="flex gap-x-2 mb-4 border-b border-gray-200 dark:border-gray-700"
                aria-label="Switch tabs"
            >
                <Tabs.Trigger
                    className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                    value="responses"
                >
                    Responses
                </Tabs.Trigger>
                <Tabs.Trigger
                    className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                    value="questions"
                >
                    Questions
                </Tabs.Trigger>
                <Tabs.Trigger
                    className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                    value="flow"
                >
                    Flow
                </Tabs.Trigger>
                <Tabs.Trigger
                    className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                    value="about"
                >
                    About
                </Tabs.Trigger>
            </Tabs.List>
            <Tabs.Content value="responses">
                <div className="space-y-4 py-3">
                    <DataTable
                        columns={object.data.table.columns}
                        records={object.data.table.records}
                    />
                </div>
            </Tabs.Content>
            <Tabs.Content value="questions">
                <div className="space-y-4 py-3">
                    {object.data.survey.questions.map((question, index) => (
                        <Question
                            key={index}
                            questionOptions={question.question_options}
                            questionText={question.question_text}
                            questionType={question.question_type}
                        />
                    ))}
                </div>
            </Tabs.Content>
            <Tabs.Content value="flow">
                <SurveyFlow
                    questions={object.data.survey.questions}
                    rule_collection={object.data.survey.rule_collection}
                />
            </Tabs.Content>
            <Tabs.Content value="about">
                <ResultsAbout results={object} />
            </Tabs.Content>
        </Tabs.Root>
    );
}

export default Results;
