import { Link } from 'react-router-dom';

function CoopLoggingIn() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const loginImageURL = new URL(
        '../../../../../public/assets/getting-started/login.png',
        import.meta.url
    ).pathname;

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">
                    Coop: Creating an account
                </h1>
                <p className="text-lg">
                    Coop is a our web app that interacts with and enhances EDSL.
                </p>
                <p className="text-lg">
                    Coop makes it easy to store your work, share it with your
                    teammates, and explore AI-powered research by other users.
                </p>
                <br />
                <h2 className="font-bold text-2xl pb-2">Create an account</h2>
                <p>
                    Go to the{' '}
                    <Link to="/login" className={linkStyles}>
                        Coop login page
                    </Link>{' '}
                    and select <span className="font-bold">Sign up</span>. You
                    can sign up with your Google or Microsoft account
                    (recommended), or using your email address and a password
                    (we'll send you a verification email with a link).
                </p>
                <img
                    src={loginImageURL}
                    alt="Screenshot of the Coop sign up tab on the login page"
                    className="max-w-md mx-auto"
                />
            </div>
        </>
    );
}

export default CoopLoggingIn;
