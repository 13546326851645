import { json } from 'react-router-dom';

export async function loader({ params }) {
    const origin = window.location.origin;

    let api_endpoint = '';
    if (origin === 'http://localhost' || origin === 'http://localhost:1234') {
        api_endpoint = 'http://localhost:8000';
    } else if (origin === 'https://chick.expectedparrot.com') {
        api_endpoint = 'https://chickapi.expectedparrot.com';
    } else if (origin === 'https://www.expectedparrot.com') {
        api_endpoint = 'https://api.expectedparrot.com';
    } else {
        api_endpoint = origin; // Fallback to the current origin if none of the conditions match
    }

    const response = await fetch(
        `${api_endpoint}/content-by-alias/${params.username}/${params.contentAlias}`,
        {
            method: 'GET',
            credentials: 'include',
        }
    );
    if (response.ok) {
        return response;
    } else if (response.status === 403) {
        throw json(
            { message: 'You are not authorized to access this object.' },
            { status: response.status }
        );
    } else if (response.status === 504) {
        throw json(
            { message: 'No response from server.' },
            { status: response.status }
        );
    } else {
        const errorData = await response.json();
        throw json({ message: errorData.detail }, { status: response.status });
    }
}
