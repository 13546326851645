function Notebook({
    notebookContent,
    openInColabLink,
}: {
    notebookContent: string;
    openInColabLink: string | null;
}) {
    return (
        <div>
            {openInColabLink && (
                <a
                    href={openInColabLink}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="https://colab.research.google.com/assets/colab-badge.svg"
                        alt="Open In Colab"
                    />
                </a>
            )}
            <div
                className="bg-white"
                dangerouslySetInnerHTML={{
                    __html: notebookContent,
                }}
            />
        </div>
    );
}

export default Notebook;
