import questionSchema from './questionSchema';
import {
    Question as EDSLQuestion,
    QuestionType,
    BudgetQuestion,
    CheckBoxQuestion,
    FreeTextQuestion,
    LikertFiveQuestion,
    LinearScaleQuestion,
    ListQuestion,
    MultipleChoiceQuestion,
    NumericalQuestion,
    RankQuestion,
    TopKQuestion,
    YesNoQuestion,
    Operator,
} from './types';

function addIdsToOptions(defaultOptions: { text: string }[]) {
    const optionsWithIds = defaultOptions.map((option) => {
        return {
            id: crypto.randomUUID(),
            text: option.text,
        };
    });
    return optionsWithIds;
}

function addIdsToLabeledOptions(
    defaultOptions: { text: string; label: string }[]
) {
    const optionsWithIds = defaultOptions.map((option) => {
        return {
            id: crypto.randomUUID(),
            text: option.text,
            label: option.label,
        };
    });
    return optionsWithIds;
}

function getNewQuestion(type: QuestionType): EDSLQuestion {
    const baseQuestion = {
        id: crypto.randomUUID(),
        text: '',
        rules: [],
    };

    switch (type) {
        case 'budget': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: BudgetQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'checkbox': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: CheckBoxQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'free_text': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: FreeTextQuestion = {
                ...baseQuestion,
                type: type,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'likert_five': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: LikertFiveQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'linear_scale': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: LinearScaleQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToLabeledOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'list': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: ListQuestion = {
                ...baseQuestion,
                type: type,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'multiple_choice': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: MultipleChoiceQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'numerical': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: NumericalQuestion = {
                ...baseQuestion,
                type: type,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'rank': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: RankQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'top_k': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: TopKQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
        case 'yes_no': {
            const schemaItem = questionSchema.find((q) => q.type === type);
            const newQuestion: YesNoQuestion = {
                ...baseQuestion,
                type: type,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
            };
            return newQuestion;
        }
    }
}

function getQuestionWithUpdatedType(
    question: EDSLQuestion,
    newType: QuestionType
): EDSLQuestion {
    switch (newType) {
        case 'budget': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
                rules: [],
            };
        }
        case 'checkbox': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
                rules: [],
            };
        }
        case 'free_text': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
                rules: [],
            };
        }
        case 'likert_five': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
                rules: [],
            };
        }
        case 'linear_scale': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToLabeledOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
                rules: [],
            };
        }
        case 'list': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
                rules: [],
            };
        }
        case 'multiple_choice': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
                rules: [],
            };
        }
        case 'numerical': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: { ...schemaItem.structure },
                constraints: { ...schemaItem.constraints },
                rules: [],
            };
        }
        case 'rank': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
                rules: [],
            };
        }
        case 'top_k': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
                rules: [],
            };
        }
        case 'yes_no': {
            const schemaItem = questionSchema.find((q) => q.type === newType);
            return {
                ...question,
                type: newType,
                structure: {
                    ...schemaItem.structure,
                    options: addIdsToOptions(
                        schemaItem.constraints.defaultOptions
                    ),
                },
                constraints: { ...schemaItem.constraints },
                rules: [],
            };
        }
    }
}

// Adds constraints to existing EDSL questions
function getQuestionFromData(question: EDSLQuestion): EDSLQuestion {
    switch (question.type) {
        case 'budget': {
            const schemaItem = questionSchema.find((q) => q.type === 'budget');
            return {
                ...question,
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'checkbox': {
            const schemaItem = questionSchema.find(
                (q) => q.type === 'checkbox'
            );
            return {
                ...question,
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'free_text': {
            const schemaItem = questionSchema.find(
                (q) => q.type === 'free_text'
            );
            return {
                ...question,
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'likert_five': {
            const schemaItem = questionSchema.find(
                (q) => q.type === 'likert_five'
            );
            return {
                ...question,
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'linear_scale': {
            const schemaItem = questionSchema.find(
                (q) => q.type === 'linear_scale'
            );
            return {
                ...question,
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'list': {
            const schemaItem = questionSchema.find((q) => q.type === 'list');
            return {
                ...question,
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'multiple_choice': {
            const schemaItem = questionSchema.find(
                (q) => q.type === 'multiple_choice'
            );
            return {
                ...question,
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'numerical': {
            const schemaItem = questionSchema.find(
                (q) => q.type === 'numerical'
            );
            return {
                ...question,
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'rank': {
            const schemaItem = questionSchema.find((q) => q.type === 'rank');
            return {
                ...question,
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'top_k': {
            const schemaItem = questionSchema.find((q) => q.type === 'top_k');
            return {
                ...question,
                constraints: { ...schemaItem.constraints },
            };
        }
        case 'yes_no': {
            const schemaItem = questionSchema.find((q) => q.type === 'yes_no');
            return {
                ...question,
                constraints: { ...schemaItem.constraints },
            };
        }
    }
}

function getDefaultConditionValues(question: EDSLQuestion): {
    connector: 'and' | 'or' | null;
    operator: Operator;
    defaultValue: string;
    question_id: string;
} {
    const baseValues = {
        connector: null as 'and' | 'or' | null,
        question_id: question.id,
    };

    switch (question.type) {
        case 'checkbox':
        case 'top_k':
            return {
                ...baseValues,
                operator: 'contains',
                defaultValue: question.structure.options[0].id,
            };
        case 'free_text':
            return {
                ...baseValues,
                operator: 'contains',
                defaultValue: '',
            };
        case 'likert_five':
        case 'multiple_choice':
        case 'yes_no':
            return {
                ...baseValues,
                operator: 'is',
                defaultValue: question.structure.options[0].id,
            };
        case 'numerical':
            return {
                ...baseValues,
                operator: 'is_equal_to',
                defaultValue: '0',
            };
    }
}

export {
    getNewQuestion,
    getQuestionWithUpdatedType,
    getQuestionFromData,
    getDefaultConditionValues,
};
