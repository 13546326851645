import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
    ReactFlow,
    ReactFlowProvider,
    Background,
    Controls,
    MarkerType,
    useNodesInitialized,
    useEdgesState,
    useNodesState,
    Edge,
} from '@xyflow/react';
import Dagre from '@dagrejs/dagre';
import '@xyflow/react/dist/style.css';

// After adding all initial edges and relevant rules, filter the edges to remove duplicates
// This will also ensure that "True" edges are prioritized over general edges
// TODO: We should really be doing this EDSL-side
const filterDuplicateEdges = (edges: Edge[]): Edge[] => {
    // Group edges by source
    const edgeGroups = edges.reduce<Record<string, Edge[]>>((groups, edge) => {
        const key = `${edge.source}`;
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(edge);
        return groups;
    }, {});

    // Process each source group
    return Object.values(edgeGroups).flatMap((group) => {
        // Group by target within each source group
        const targetGroups = group.reduce<Record<string, Edge[]>>(
            (acc, edge) => {
                const key = `${edge.target}`;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(edge);
                return acc;
            },
            {}
        );

        // For each target group, select the highest priority/most recent edge
        const selectedEdges = Object.values(targetGroups).map((targetGroup) => {
            return targetGroup.sort((a, b) => {
                const priorityA = Number(a.data?.priority ?? -1);
                const priorityB = Number(b.data?.priority ?? -1);
                if (priorityB !== priorityA) {
                    return priorityB - priorityA;
                }
                return targetGroup.indexOf(b) - targetGroup.indexOf(a);
            })[0];
        });

        // If any selected edge has a "True" expression, only keep that edge
        const trueEdge = selectedEdges.find((edge) => edge.label === 'if True');
        if (trueEdge) {
            return [trueEdge];
        }

        return selectedEdges;
    });
};

const getLayoutedElements = (nodes, edges, options) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({ rankdir: options.direction, edgesep: 250 });

    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) =>
        g.setNode(node.id, {
            ...node,
            width: node.measured?.width ?? 0,
            height: node.measured?.height ?? 0,
        })
    );

    Dagre.layout(g);

    return {
        layoutedNodes: nodes.map((node) => {
            const position = g.node(node.id);
            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).
            const x = position.x - (node.measured?.width ?? 0) / 2;
            const y = position.y - (node.measured?.height ?? 0) / 2;

            return { ...node, position: { x, y } };
        }),
        layoutedEdges: edges,
    };
};

function SurveyFlow({
    questions,
    rule_collection,
}: {
    questions: {
        question_name: string;
        question_options?: string[];
        question_text: string;
        question_type: string;
    }[];
    rule_collection: {
        rules: {
            current_q: number;
            expression: string;
            next_q: number;
            priority: number;
        }[];
    };
}) {
    const isDarkMode: boolean = useOutletContext();

    const colors = {
        primaryEdge: isDarkMode ? '#d1d5db' : 'black',
        secondaryEdge: isDarkMode ? '#22d3ee' : 'blue',
    };

    const initialNodes = [];

    for (let [index, question] of questions.entries()) {
        initialNodes.push({
            id: index.toString(),
            position: { x: 0, y: index * 100 },
            data: {
                label: `${question.question_name}: ${question.question_text}`,
            },
            draggable: false,
        });
    }

    initialNodes.push({
        id: 'EndOfSurvey',
        position: { x: 0, y: initialNodes.length * 100 },
        data: { label: 'End of Survey' },
        draggable: false,
    });

    const initialEdges = [];

    for (let i = 0; i < initialNodes.length - 2; i++) {
        initialEdges.push({
            id: `${i}-${i + 1}`,
            source: `${i}`,
            target: `${i + 1}`,
            markerEnd: {
                type: MarkerType.ArrowClosed,
                color: colors.primaryEdge,
            },
            style: {
                strokeWidth: 1.5,
                stroke: colors.primaryEdge,
            },
        });
    }

    initialEdges.push({
        id: `${initialNodes.length - 2}-EndOfSurvey`,
        source: `${initialNodes.length - 2}`,
        target: 'EndOfSurvey',
        markerEnd: {
            type: MarkerType.ArrowClosed,
            color: colors.primaryEdge,
        },
        style: {
            strokeWidth: 1.5,
            stroke: colors.primaryEdge,
        },
    });

    const rulePriorityDefaultValue = -1;
    const relevantRules = rule_collection.rules.filter(
        (rule) => rule.priority > rulePriorityDefaultValue
    );

    relevantRules.forEach((rule) => {
        const source = rule.current_q;
        const target =
            rule.next_q === initialNodes.length - 1
                ? 'EndOfSurvey'
                : rule.next_q;

        initialEdges.push({
            id: `${source}-${target}`,
            source: `${source}`,
            target: `${target}`,
            label: `if ${rule.expression}`,
            markerEnd: {
                type: MarkerType.ArrowClosed,
                color: colors.secondaryEdge,
            },
            style: {
                strokeWidth: 1.5,
                stroke: colors.secondaryEdge,
            },
        });
    });

    const options = {
        includeHiddenNodes: true,
    };
    const nodesInitialized = useNodesInitialized(options);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);

    // Filter out duplicate edges and prioritize "True" edges
    const filteredEdges = filterDuplicateEdges(initialEdges);

    const [edges, setEdges, onEdgesChange] = useEdgesState(filteredEdges);

    // Fix layout once the graph has been initialized
    useEffect(() => {
        if (nodesInitialized) {
            const direction = 'TB'; // Top to bottom
            const { layoutedNodes, layoutedEdges } = getLayoutedElements(
                nodes,
                edges,
                {
                    direction,
                }
            );
            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        }
    }, [nodesInitialized]);

    return (
        <div className="h-[60vh]">
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                colorMode={isDarkMode ? 'dark' : 'light'}
                fitView
            >
                <Background />
                <Controls />
            </ReactFlow>
        </div>
    );
}

function SurveyFlowWrapper({
    questions,
    rule_collection,
}: {
    questions: {
        question_name: string;
        question_options?: string[];
        question_text: string;
        question_type: string;
    }[];
    rule_collection: {
        rules: {
            current_q: number;
            expression: string;
            next_q: number;
            priority: number;
        }[];
    };
}) {
    return (
        <ReactFlowProvider>
            <SurveyFlow
                questions={questions}
                rule_collection={rule_collection}
            />
        </ReactFlowProvider>
    );
}

export default SurveyFlowWrapper;
