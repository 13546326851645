import { Form, Link, useLoaderData } from 'react-router-dom';
import { ArrowRight } from 'react-feather';
import StatusDropdown from './StatusDropdown';
import Pagination from '../../../base/Pagination';
import RemoteJobTable from '../../../base/RemoteJobTable';
import { Job } from '../../../../types';

interface RemoteInferenceData {
    job_count: number;
    search_results_count: number;
    jobs: Job[];
    current_page: number;
    page_size: number;
    total_pages: number;
    status_filters: string[] | null;
    status_counter: {
        queued: number;
        running: number;
        cancelling: number;
        cancelled: number;
        failed: number;
        completed: number;
    };
    is_authenticated: boolean;
    is_admin: boolean;
}

function RemoteInference() {
    const data = useLoaderData() as RemoteInferenceData;

    const startIndex = (data.current_page - 1) * data.page_size + 1;
    const endIndex = Math.min(
        startIndex + data.page_size - 1,
        data.search_results_count
    );

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Remote Inference</h1>
                <p className="text-lg pb-2">
                    Manage your remote inference jobs and view your results.
                </p>
                <Link
                    to="/home/pricing"
                    className="block pb-2 text-blue-500 hover:text-blue-600 dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                >
                    View available models and their prices
                    <ArrowRight className="inline w-4 h-4 ml-1" />
                </Link>
            </div>
            <div className="space-y-4 mt-8">
                <h2 className="font-bold text-2xl">History</h2>
                {data.search_results_count === 0 ? (
                    <p>Showing 0 jobs</p>
                ) : (
                    <p>
                        Showing {startIndex}-{endIndex} of{' '}
                        {data.search_results_count} jobs
                    </p>
                )}
                {/* Allow admin to search by username and job description */}
                {data.is_admin && (
                    <Form className="relative">
                        <input
                            type="text"
                            name="search_query"
                            className="w-full px-4 py-2 text-sm border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:placeholder-gray-400"
                            placeholder="Search by owner username or description..."
                        />
                    </Form>
                )}
                <div className="flex justify-between">
                    <StatusDropdown />
                    <Pagination
                        style="grayscale"
                        currentPage={data.current_page}
                        totalPages={data.total_pages}
                    />
                </div>
                <RemoteJobTable jobs={data.jobs} isAdmin={data.is_admin} />
            </div>
        </>
    );
}

export default RemoteInference;
