import { redirect } from 'react-router-dom';

export async function action({ request }) {
    let data;
    let intent;

    const contentType = request.headers.get('Content-Type');

    if (contentType && contentType.includes('application/json')) {
        // console.log('Handling JSON data...');
        data = await request.json();
        intent = data.intent;
    } else {
        // console.log('Handling form data...');
        data = await request.formData();
        intent = data.get('intent');
    }

    let response: Response;

    if (intent === 'create_job') {
        const traits = data.get('traits');
        const agents = data.get('agents');
        const file = data.get('file');
        const projectId = data.get('projectId');
        // console.log(JSON.parse(traits));
        // console.log(JSON.parse(agents));
        // console.log(file);
        // console.log(typeof file);
        // console.log(projectId);
        const formData = new FormData();
        formData.append('json_traits', traits);
        formData.append('json_agents', agents);
        formData.append('project_uuid', projectId);
        if (file !== 'null') {
            formData.append('file', file);
        }
        response = await fetch(`/api/projects/create-job`, {
            method: 'POST',
            body: formData,
        });
    } else if (intent === 'retry_job') {
        response = await fetch(`/api/home/remote-inference`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_uuid: data.get('job_uuid'),
                status: 'queued',
            }),
        });
    } else if (intent === 'cancel_job') {
        response = await fetch(`/api/home/remote-inference`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_uuid: data.get('job_uuid'),
                status: 'cancelling',
            }),
        });
    } else if (intent === 'upload_scenarios') {
        const file = data.get('file');
        console.log(file);
        const formData = new FormData();
        formData.append('file', file);

        response = await fetch('/api/upload-scenarios', {
            method: 'POST',
            body: formData,
        });
    } else if (intent === 'edit_project') {
        const projectId = data.get('project_uuid');
        response = await fetch(`/api/projects/${projectId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: data.get('project_name'),
            }),
        });
    } else if (intent === 'delete_project') {
        const projectId = data.get('project_uuid');
        response = await fetch(`/api/projects/${projectId}`, {
            method: 'DELETE',
        });
    }

    if (response.ok && intent === 'create_job') {
        const successData = await response.json();
        return successData.message;
    } else if (response.ok && intent === 'retry_job') {
        return 'Success! Job added to queue.';
    } else if (response.ok && intent === 'cancel_job') {
        return 'Success! Cancellation request sent.';
    } else if (response.ok && intent === 'upload_scenarios') {
        return response;
    } else if (response.ok && intent === 'edit_project') {
        const successData = await response.json();
        return { success: true, message: successData.message };
    } else if (response.ok && intent === 'delete_project') {
        return redirect('/home/projects');
    } else if (!response.ok && intent === 'edit_project') {
        // The ability to edit projects has been added later, so it uses the new actions structure
        // TODO: Migrate other actions to the { success: bool, message: str } structure
        if (response.status === 422) {
            return { success: false, message: 'Pydantic error.' };
        } else if (response.status === 504) {
            return { success: false, message: 'No response from server.' };
        } else {
            const errorData = await response.json();
            return { success: false, message: errorData.detail };
        }
    } else if (response.status === 422) {
        const errorData = await response.json();
        // console.log(errorData);
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}
