import * as Select from '@radix-ui/react-select';
import { Check, ChevronDown, ChevronUp, Plus, Trash2 } from 'react-feather';
import {
    Question as EDSLQuestion,
    Rule as RuleType,
    Condition as ConditionType,
} from './types';
import { getDefaultConditionValues } from './helpers';

interface RuleProps {
    rule: RuleType;
    question: EDSLQuestion;
    questions: EDSLQuestion[];
    questionIndex: number;
    updateRule: (
        questionId: string,
        ruleId: string,
        updatedRule: RuleType
    ) => void;
    removeRule: (questionId: string, ruleId: string) => void;
    addCondition: (questionId: string, ruleId: string) => void;
    updateCondition: (
        questionId: string,
        ruleId: string,
        conditionId: string,
        updatedCondition: ConditionType
    ) => void;
    removeCondition: (
        questionId: string,
        ruleId: string,
        conditionId: string
    ) => void;
}

interface ConditionProps {
    condition: ConditionType;
    conditionIndex: number;
    question: EDSLQuestion;
    questions: EDSLQuestion[];
    questionIndex: number;
    rule: RuleType;
    updateCondition: (
        questionId: string,
        ruleId: string,
        conditionId: string,
        updatedCondition: ConditionType
    ) => void;
    removeCondition: (
        questionId: string,
        ruleId: string,
        conditionId: string
    ) => void;
}

interface QuestionLogicProps {
    question: EDSLQuestion;
    questionIndex: number;
    questions: EDSLQuestion[];
    updateRule: (
        questionId: string,
        ruleId: string,
        updatedRule: RuleType
    ) => void;
    removeRule: (questionId: string, ruleId: string) => void;
    addCondition: (questionId: string, ruleId: string) => void;
    updateCondition: (
        questionId: string,
        ruleId: string,
        conditionId: string,
        updatedCondition: ConditionType
    ) => void;
    removeCondition: (
        questionId: string,
        ruleId: string,
        conditionId: string
    ) => void;
}

function SelectWrapper({ value, onValueChange, items, placeholder }) {
    return (
        <Select.Root value={value} onValueChange={onValueChange}>
            <Select.Trigger className="inline-flex items-center justify-center border border-gray-300 dark:border-2 dark:border-gray-500 rounded px-4 py-2 text-sm h-9 gap-2 bg-white dark:bg-primary-dark-bg focus:shadow-outline">
                <Select.Value placeholder={placeholder} />
                <Select.Icon>
                    <ChevronDown className="ml-3 w-5" strokeWidth={1.5} />
                </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
                <Select.Content className="overflow-hidden bg-white rounded-md shadow-lg">
                    <Select.ScrollUpButton className="flex items-center justify-center h-6 bg-white text-blue-900 cursor-default">
                        <ChevronUp />
                    </Select.ScrollUpButton>
                    <Select.Viewport className="p-1">
                        {items.map((item) => (
                            <Select.Item
                                key={item.value}
                                value={item.value}
                                className="text-sm text-blue-900 rounded flex items-center h-6 px-9 py-4 relative select-none data-[highlighted]:outline-none data-[highlighted]:bg-blue-900 data-[highlighted]:text-blue-100"
                            >
                                <Select.ItemText>{item.label}</Select.ItemText>
                                <Select.ItemIndicator className="absolute left-1 w-5 inline-flex items-center justify-center">
                                    <Check />
                                </Select.ItemIndicator>
                            </Select.Item>
                        ))}
                    </Select.Viewport>
                </Select.Content>
            </Select.Portal>
        </Select.Root>
    );
}

function ValueInput({ condition, questions, onValueChange }) {
    const selectedQuestion = questions.find(
        (q) => q.id === condition.question_id
    );

    switch (selectedQuestion.type) {
        case 'checkbox':
        case 'top_k':
        case 'likert_five':
        case 'multiple_choice':
        case 'yes_no':
            const options =
                'structure' in selectedQuestion &&
                'options' in selectedQuestion.structure
                    ? selectedQuestion.structure.options.map((option) => ({
                          value: option.id,
                          label: option.text,
                      }))
                    : [];

            return (
                <SelectWrapper
                    value={condition.value}
                    onValueChange={onValueChange}
                    items={options}
                    placeholder="Select value"
                />
            );

        case 'numerical':
            return (
                <input
                    type="number"
                    value={condition.value}
                    onChange={(e) => onValueChange(e.target.value)}
                    className="px-4 py-2 border border-gray-300 dark:border-gray-500 rounded"
                    placeholder="Enter number"
                />
            );

        case 'free_text':
            return (
                <input
                    type="text"
                    value={condition.value}
                    onChange={(e) => onValueChange(e.target.value)}
                    className="px-4 py-2 border border-gray-300 dark:border-gray-500 rounded"
                    placeholder="Enter text"
                />
            );

        default:
            return null;
    }
}

function Rule({
    rule,
    question,
    questions,
    questionIndex,
    updateRule,
    removeRule,
    addCondition,
    updateCondition,
    removeCondition,
}: RuleProps) {
    // The question to go to if the rule's expression evaluates to True
    // This list contains every question in the survey except the current question
    // It also contains the end of survey
    const nextQuestionOptions = [
        { value: 'end_of_survey', label: 'End of survey' },
        ...questions
            .map((q, index) => ({
                value: q.id,
                label: `${index + 1}. ${q.text}`,
            }))
            .filter((option) => option.value !== question.id),
    ];

    const firstWordOptions =
        question.rules.length > 1
            ? [{ value: 'if', label: 'If' }]
            : [
                  { value: 'if', label: 'If' },
                  { value: 'always', label: 'Always' },
              ];

    const handleFirstWordChange = (value: 'if' | 'always') => {
        if (value === 'if') {
            const { question_id, connector, operator, defaultValue } =
                getDefaultConditionValues(question);
            updateRule(question.id, rule.id, {
                ...rule,
                first_word: value,
                conditions: [
                    {
                        id: crypto.randomUUID(),
                        question_id,
                        connector,
                        operator,
                        value: defaultValue,
                    },
                ],
            });
        } else if (value === 'always') {
            updateRule(question.id, rule.id, {
                ...rule,
                first_word: value,
                conditions: [],
            });
        }
    };

    return (
        <div className="ml-8 space-y-4 pb-6 border-b-2 border-gray-200 dark:border-gray-700">
            <div className="flex justify-between items-center">
                <SelectWrapper
                    value={rule.first_word}
                    onValueChange={handleFirstWordChange}
                    items={firstWordOptions}
                    placeholder="Select first word"
                />
                <button
                    onClick={() => removeRule(question.id, rule.id)}
                    className="p-2 text-gray-600 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-full"
                >
                    <Trash2 className="w-4 h-4" />
                </button>
            </div>
            {rule.first_word !== 'always' && (
                <div className="space-y-4">
                    {rule.conditions.map((condition, conditionIndex) => (
                        <Condition
                            key={condition.id}
                            condition={condition}
                            conditionIndex={conditionIndex}
                            question={question}
                            questions={questions}
                            questionIndex={questionIndex}
                            rule={rule}
                            updateCondition={updateCondition}
                            removeCondition={removeCondition}
                        />
                    ))}

                    <button
                        onClick={() => addCondition(question.id, rule.id)}
                        className="flex items-center gap-2 px-4 py-2 hover:bg-gray-300/20 transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                    >
                        <Plus className="w-4 h-4" />
                        Add condition
                    </button>
                </div>
            )}
            <div className="flex gap-4 items-center mt-4">
                <span>Go to:</span>
                <SelectWrapper
                    value={rule.next_question_id}
                    onValueChange={(value) => {
                        updateRule(question.id, rule.id, {
                            ...rule,
                            next_question_id: value,
                        });
                    }}
                    items={nextQuestionOptions}
                    placeholder="Select target"
                />
            </div>
        </div>
    );
}

function Condition({
    condition,
    conditionIndex,
    question,
    questions,
    questionIndex,
    rule,
    updateCondition,
    removeCondition,
}: ConditionProps) {
    const connectorOptions = [
        { value: 'and', label: 'and' },
        { value: 'or', label: 'or' },
    ];

    // You cannot add conditions to questions that have not yet been answered
    // For example, if you are on Q3, you cannot add a condition that depends on Q10
    const questionOptions = questions
        .filter((q, index) => index <= questionIndex)
        .map((q, index) => ({
            value: q.id,
            label: `${index + 1}. ${q.text}`,
        }));

    const getOperatorOptions = (questionType: string) => {
        switch (questionType) {
            case 'checkbox':
            case 'top_k':
            case 'free_text':
                return [
                    { value: 'contains', label: 'contains' },
                    { value: 'does_not_contain', label: 'does not contain' },
                ];
            case 'likert_five':
            case 'multiple_choice':
            case 'yes_no':
                return [
                    { value: 'is', label: 'is' },
                    { value: 'is_not', label: 'is not' },
                ];
            case 'numerical':
                return [
                    { value: 'is_equal_to', label: 'is equal to' },
                    { value: 'is_not_equal_to', label: 'is not equal to' },
                    { value: 'is_less_than', label: 'is less than' },
                    {
                        value: 'is_less_than_or_equal_to',
                        label: 'is less than or equal to',
                    },
                    { value: 'is_greater_than', label: 'is greater than' },
                    {
                        value: 'is_greater_than_or_equal_to',
                        label: 'is greater than or equal to',
                    },
                ];
            default:
                return [];
        }
    };

    return (
        <div className="flex flex-col gap-4 bg-gray-100 dark:bg-slate-700/30 p-4 rounded-lg">
            <div className="flex justify-between items-center">
                <div className="flex gap-4 items-center">
                    {/* The first condition does not have a connector */}
                    {condition.connector !== null && (
                        <SelectWrapper
                            value={condition.connector}
                            onValueChange={(value) => {
                                updateCondition(
                                    question.id,
                                    rule.id,
                                    condition.id,
                                    {
                                        ...condition,
                                        connector: value,
                                    }
                                );
                            }}
                            items={connectorOptions}
                            placeholder="Select connector"
                        />
                    )}
                    {/* Select a question */}
                    <SelectWrapper
                        value={condition.question_id}
                        onValueChange={(value) => {
                            const newQuestion = questions.find(
                                (q) => q.id === value
                            );
                            const defaultValues =
                                getDefaultConditionValues(newQuestion);

                            updateCondition(
                                question.id,
                                rule.id,
                                condition.id,
                                {
                                    ...condition,
                                    question_id: value,
                                    operator: defaultValues.operator,
                                    value: defaultValues.defaultValue,
                                }
                            );
                        }}
                        items={questionOptions}
                        placeholder="Select question"
                    />
                </div>
                {/* A rule must have at least one condition */}
                {/* You can't remove the first condition (index < 0) */}
                {conditionIndex > 0 && (
                    <button
                        onClick={() =>
                            removeCondition(question.id, rule.id, condition.id)
                        }
                        className="p-2 text-gray-600 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-full"
                    >
                        <Trash2 className="w-4 h-4" />
                    </button>
                )}
            </div>
            {/* Select an operator: is equal to, is not equal to, etc. */}
            <div className="flex gap-4 items-center">
                <SelectWrapper
                    value={condition.operator}
                    onValueChange={(value) => {
                        updateCondition(question.id, rule.id, condition.id, {
                            ...condition,
                            operator: value,
                        });
                    }}
                    items={getOperatorOptions(
                        questions.find((q) => q.id === condition.question_id)
                            ?.type
                    )}
                    placeholder="Select operator"
                />
                {/* Select a value */}
                {/* e.g., for MC questions, the values would be the question options */}
                <ValueInput
                    condition={condition}
                    questions={questions}
                    onValueChange={(value) => {
                        updateCondition(question.id, rule.id, condition.id, {
                            ...condition,
                            value,
                        });
                    }}
                />
            </div>
        </div>
    );
}

function QuestionLogic({
    question,
    questionIndex,
    questions,
    updateRule,
    removeRule,
    addCondition,
    updateCondition,
    removeCondition,
}: QuestionLogicProps) {
    return (
        <div className="space-y-4">
            {question.rules.map((rule: RuleType) => (
                <Rule
                    key={rule.id}
                    rule={rule}
                    question={question}
                    questions={questions}
                    questionIndex={questionIndex}
                    updateRule={updateRule}
                    removeRule={removeRule}
                    addCondition={addCondition}
                    updateCondition={updateCondition}
                    removeCondition={removeCondition}
                />
            ))}
        </div>
    );
}

export default QuestionLogic;
