import { json, redirect } from 'react-router-dom';

export async function loader({ request }) {
    const url = new URL(request.url);
    const start_date = url.searchParams.get('start_date');
    const end_date = url.searchParams.get('end_date');
    const period = url.searchParams.get('period');
    const response = await fetch(
        `/api/admin/user-statistics/?start_date=${start_date || ''}&end_date=${end_date || ''}&period=${period || 'weekly'}`,
        {
            method: 'GET',
        }
    );

    if (response.ok) {
        return response;
    } else if (response.status === 401) {
        return redirect('/admin/login');
    } else if (response.status === 504) {
        throw json(
            { message: 'No response from server.' },
            { status: response.status }
        );
    } else {
        const errorData = await response.json();
        throw json({ message: errorData.detail }, { status: response.status });
    }
}
