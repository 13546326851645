import { Link, useLoaderData } from 'react-router-dom';
import APIKey from './APIKey';
import EDSLSettingsToggle from './EDSLSettingsToggle';
import ResetAPIKeyForm from './ResetAPIKeyForm';

interface EDSLSettings {
    remote_caching: boolean;
    remote_inference: boolean;
    remote_logging: boolean;
}

interface APIData {
    is_authenticated: boolean;
    api_key: string;
    username: string;
    edsl_settings: EDSLSettings;
}

function API() {
    const apiData = useLoaderData() as APIData;

    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Settings</h1>
                <p>
                    Our API enhances EDSL by allowing it to interact with Coop.
                </p>
                <ul className="list-disc pl-5">
                    <li>Use Coop to store and retrieve EDSL content.</li>
                    <li>Use remote caching to save your LLM call results.</li>
                    <li>
                        Use remote inference to run your workflows on Coop
                        (consumes credits).
                    </li>
                </ul>
            </div>
            {/* API Key */}
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">API Key</h2>
                <div className="inline-flex">
                    <APIKey apiKey={apiData.api_key} />
                    <ResetAPIKeyForm />
                </div>
                <p>
                    To use your API key, add{' '}
                    <span className="p-1 bg-gray-100 dark:bg-gray-100/10 font-mono">
                        EXPECTED_PARROT_API_KEY
                    </span>{' '}
                    to your <i>.env</i> file (
                    <a
                        href="https://docs.expectedparrot.com/en/latest/api_keys.html"
                        className={linkStyles}
                        target="_blank"
                    >
                        see examples
                    </a>
                    ).
                </p>
            </div>
            {/* EDSL Settings */}
            <div className="space-y-2">
                <h2 className="text-2xl font-bold">EDSL Settings</h2>
                <EDSLSettingsToggle
                    label="Remote caching"
                    id="remote-caching"
                    defaultValue={apiData.edsl_settings.remote_caching}
                    name="intent"
                    value="remote-caching"
                />
                <EDSLSettingsToggle
                    label="Remote inference"
                    id="remote-inference"
                    defaultValue={apiData.edsl_settings.remote_inference}
                    name="intent"
                    value="remote-inference"
                />
                <EDSLSettingsToggle
                    label="Remote logging"
                    id="remote-logging"
                    defaultValue={apiData.edsl_settings.remote_logging}
                    name="intent"
                    value="remote-logging"
                />
            </div>
        </>
    );
}

export default API;
