import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import JobHistory from './JobHistory';
import { CancelBtn, ProgressLink, RetryBtn } from './ActionButtons';
import { formatDate } from '../../../utils';
import { Job } from '../../../types';

interface RemoteJobTableProps {
    isAdmin?: boolean;
    jobs: Job[];
}

function RemoteJobTable({ jobs, isAdmin = false }: RemoteJobTableProps) {
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

    function toggleRow(id: string) {
        setExpandedRows((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    }

    function getResults(
        resultsStatus: string,
        resultsUUID: string,
        isAdmin: boolean
    ) {
        if (resultsStatus === 'completed') {
            const resultsLink = isAdmin
                ? `/admin/objects/${resultsUUID}`
                : `/content/${resultsUUID}`;
            return (
                <Link
                    to={resultsLink}
                    target="_blank"
                    className="text-blue-500 hover:text-blue-600 dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                >
                    View
                </Link>
            );
        } else if (resultsStatus === 'not applicable') {
            return 'None';
        } else if (resultsStatus === 'deleted') {
            return 'Deleted by user';
        } else if (resultsStatus === 'pending') {
            return 'Pending';
        }
    }

    return (
        <table className="w-full table-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden text-center">
            <thead className="bg-gray-100 dark:bg-gray-700 border-gray-200 dark:border-gray-600 border-b-2 z-20">
                <tr>
                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                        Last Queued
                    </th>
                    {isAdmin && (
                        <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                            Owner
                        </th>
                    )}
                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                        Job Description
                    </th>
                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                        Results
                    </th>
                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                        Status
                    </th>
                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                        Credits
                    </th>
                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                {jobs.map((job, index) => (
                    <Fragment key={job.job_uuid}>
                        <tr
                            className={
                                index % 2 === 0
                                    ? 'bg-white dark:bg-gray-800 cursor-pointer'
                                    : 'bg-gray-50 dark:bg-gray-700 cursor-pointer'
                            }
                            onClick={() => toggleRow(job.job_uuid)}
                        >
                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                {formatDate(
                                    job.last_queued_ts,
                                    'MMMM D, YYYY h:mm:ss A'
                                )}
                            </td>
                            {isAdmin && (
                                <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                    {job.owner_email}
                                </td>
                            )}
                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                {job.description || 'No description provided'}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                {getResults(
                                    job.results_status,
                                    job.results_uuid,
                                    isAdmin
                                )}
                            </td>
                            <td className="px-4 py-2 capitalize text-sm text-gray-700 dark:text-gray-300">
                                {job.status}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                {job.price}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300 space-x-2">
                                {job.status === 'failed' && (
                                    <RetryBtn jobId={job.job_uuid} />
                                )}
                                {/* {(job.status === 'queued' || job.status === 'running') && 
                                <CancelBtn jobId={job.job_uuid} />} */}
                                {job.has_progress_data === true && (
                                    <ProgressLink jobId={job.job_uuid} />
                                )}
                            </td>
                        </tr>
                        {expandedRows.has(job.job_uuid) && (
                            <tr className="max-h-[40vh] text-left">
                                <JobHistory job={job} isAdmin={isAdmin} />
                            </tr>
                        )}
                    </Fragment>
                ))}
            </tbody>
        </table>
    );
}

export default RemoteJobTable;
