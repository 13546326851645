import { redirect } from 'react-router-dom';

export async function action({ request, params }) {
    const data = await request.json();
    const intent = data.intent;
    const questions = JSON.parse(data.questions);

    let response: Response;

    if (intent === 'get_code') {
        response = await fetch(`/api/survey-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ questions }),
        });
    } else if (intent === 'edit_survey') {
        response = await fetch(`/api/edit-survey-object`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ questions, survey_uuid: params.surveyId }),
        });
    } else if (intent === 'create_project') {
        const project_name = data.project_name;
        response = await fetch(
            `/api/projects/create-from-existing-survey-questions/${params.surveyId}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ questions, project_name }),
            }
        );
    }

    if (response.ok && intent === 'get_code') {
        const successData = await response.json();
        console.log(successData);
        return {
            success: true,
            intent: intent,
            message: 'Successfully retrieved code!',
            code: successData.code,
            questions: successData.questions,
            rule_collection: successData.rule_collection,
        };
    } else if (response.ok && intent === 'edit_survey') {
        const successData = await response.json();
        const objectId = successData.uuid;
        return redirect(`/content/${objectId}`);
    } else if (response.ok && intent === 'create_project') {
        const successData = await response.json();
        const projectId = successData.uuid;
        return redirect(`/home/projects/${projectId}`);
    } else if (response.status === 401) {
        return redirect('/login');
    } else if (response.status === 422) {
        const errorData = await response.json();
        console.log(errorData);
        return {
            success: false,
            intent: intent,
            message: 'Pydantic error.',
            code: '# Pydantic error.',
        };
    } else if (response.status === 504) {
        return {
            success: false,
            intent: intent,
            message: 'No response from server.',
            code: '# No response from server.',
        };
    } else {
        const errorData = await response.json();
        console.log(errorData);
        return {
            success: false,
            intent: intent,
            message: errorData.detail,
            code: `# ${errorData.detail}`,
        };
    }
}
