import { useEffect, useState } from 'react';
import { Link, Outlet, useNavigation } from 'react-router-dom';
import Header from './Header';

function LoadingBar() {
    const navigation = useNavigation();
    const isLoading = navigation.state === 'loading';

    const [progress, setProgress] = useState(0);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        let progressInterval: NodeJS.Timeout | undefined;
        let fadeTimeout: NodeJS.Timeout | undefined;
        let resetTimeout: NodeJS.Timeout | undefined;

        const threshold = 25;

        // Simulate progress while loading
        if (isLoading) {
            setOpacity(1);
            setProgress(0);

            // The progress bar slows down as it gets closer to the threshold
            progressInterval = setInterval(() => {
                setProgress((prev) => {
                    if (prev < threshold) {
                        const increment = (threshold - prev) * 0.1;
                        return Math.min(prev + increment, threshold);
                    }
                    clearInterval(progressInterval);
                    return prev;
                });
            }, 100);
        } else {
            // Navigation complete - jump to 100% progress
            setProgress(100);

            // After 200ms, fade out the loading bar
            fadeTimeout = setTimeout(() => {
                setOpacity(0);
            }, 200);

            // After 400ms, reset the progress bar
            resetTimeout = setTimeout(() => {
                setProgress(0);
            }, 400);
        }

        return () => {
            if (progressInterval) clearInterval(progressInterval);
            if (fadeTimeout) clearTimeout(fadeTimeout);
            if (resetTimeout) clearTimeout(resetTimeout);
        };
    }, [isLoading]);

    return (
        <div className="fixed top-0 left-0 right-0 h-0.5 z-50">
            <div
                className="h-full bg-blue-500 transition-all duration-300 ease-out"
                style={{
                    width: `${progress}%`,
                    opacity,
                    transition: 'width 0.3s ease-out, opacity 0.2s ease-out',
                }}
            />
        </div>
    );
}

function App() {
    const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
        const currentTheme = localStorage.getItem('theme');
        if (currentTheme === 'dark') {
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
            document.documentElement.style.colorScheme = 'dark';
        } else {
            document.documentElement.classList.remove('dark');
            document.documentElement.style.colorScheme = 'light';
        }
    }, [isDarkMode]);

    function toggleDarkMode(value: boolean) {
        setIsDarkMode(value);
        if (value === true) {
            localStorage.setItem('theme', 'dark');
        } else {
            localStorage.setItem('theme', 'light');
        }
    }

    return (
        <div className="h-full flex flex-col">
            <LoadingBar />
            <Header isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
            <main className="h-full flex-1 overflow-y-hidden flex flex-col items-center dark:bg-primary-dark-bg dark:text-primary-dark-text">
                <Outlet context={isDarkMode} />
            </main>
            <footer className="text-gray-500 border-t border-gray-200 dark:bg-primary-dark-bg dark:text-primary-dark-text dark:border-slate-700">
                <div className="flex flex-col sm:flex-row justify-center items-center space-x-1 sm:space-x-2 py-5 sm:py-4 text-sm sm:text-base">
                    <p>© 2024 Expected Parrot, Inc.</p>
                    <span className="hidden sm:inline">·</span>
                    <div className="space-x-1 sm:space-x-2">
                        <a
                            href="/terms"
                            target="_blank"
                            className="hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                        >
                            Terms
                        </a>
                        <span className="sm:inline">·</span>
                        <Link
                            to="/privacy"
                            className="hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                        >
                            Privacy
                        </Link>
                        <span className="sm:inline">·</span>
                        <a
                            href="mailto:info@expectedparrot.com"
                            className="hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                        >
                            info@expectedparrot.com
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default App;
