import { useLoaderData, useNavigate,useSearchParams} from 'react-router-dom';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { useState, useEffect } from 'react';

interface UserStatisticsData {
    is_authenticated: boolean;
    registrations: { period: string; verified: number; unverified: number }[];
    uploads_downloads: { period: string; uploads: number; downloads: number }[];
    uploaders_downloaders: { period: string; distinct_uploaders: number; distinct_downloaders: number }[];
    job_runs: { period: string; job_runs: number }[];
    job_runners: { period: string; job_runners: number }[];
    purchases: { period: string; credits_purchased: number }[];
    jobs_costs: { period: string; jobs_costs: number }[];
}

function UserStatistics() {
    const navigate = useNavigate();
    const data = useLoaderData() as UserStatisticsData;

    const today = new Date().toISOString().split('T')[0];
    const threeMonthsAgo = new Date(new Date().setMonth(new Date().getMonth() - 3))
        .toISOString()
        .split('T')[0];

    const [searchParams] = useSearchParams();

    const [startDate, setStartDate] = useState(searchParams.get('start_date') || threeMonthsAgo);
    const [endDate, setEndDate] = useState(searchParams.get('end_date') || today);
    const [period, setPeriod] = useState(searchParams.get('period') || 'weekly');

    useEffect(() => {
        // Sync state with URL params on mount
        const params = new URLSearchParams(window.location.search);
        setStartDate(params.get('start_date') || threeMonthsAgo);
        setEndDate(params.get('end_date') || today);
        setPeriod(params.get('period') || 'weekly');
    }, []);


    const updateFilters = () => {
        navigate(
            `?start_date=${startDate}&end_date=${endDate}&period=${period}`,
            { replace: true }
        );
    };

    return (
        <div className="bg-gray-50 p-4 md:p-6 rounded-lg shadow-md">
            {/* Filter Section */}
            <div className="bg-white p-4 rounded-lg shadow-md mb-6 grid gap-4 md:grid-cols-4 items-center">
                <div>
                    <label htmlFor="start_date" className="block text-sm font-medium text-gray-700">
                        Start Date
                    </label>
                    <input
                        type="date"
                        id="start_date"
                        value={startDate}
                        max={endDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="end_date" className="block text-sm font-medium text-gray-700">
                        End Date
                    </label>
                    <input
                        type="date"
                        id="end_date"
                        value={endDate}
                        min={startDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="period" className="block text-sm font-medium text-gray-700">
                        Period
                    </label>
                    <select
                        id="period"
                        value={period}
                        onChange={(e) => setPeriod(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 text-sm"
                    >
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                    </select>
                </div>
                <div className="flex justify-center">
                    <button
                        onClick={updateFilters}
                        className="w-auto mt-6 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 text-sm"
                    >
                        Update
                    </button>
                </div>
            </div>

            {/* Data Summary */}
            <p className="text-gray-600 text-sm mb-6 text-center md:text-left">
                Showing data from <strong>{startDate}</strong> to <strong>{endDate}</strong>. Data is aggregated on a <strong>{period}</strong> level.
            </p>

            {/* Chart Section */}
            <div className="space-y-8">
                {[
                    { title: "User Registrations", data: data.registrations, lines: ["verified", "unverified"] },
                    { title: "Object Uploads and Downloads", data: data.uploads_downloads, lines: ["uploads", "downloads"] },
                    { title: "Distinct Users Who Uploaded and Downloaded Objects", data: data.uploaders_downloaders, lines: ["distinct_uploaders", "distinct_downloaders"] },
                    { title: "Job Runs", data: data.job_runs, lines: ["job_runs"] },
                    { title: "Unique users Job Runners", data: data.job_runners, lines: ["job_runners"] },
                    { title: "Credit Purchases", data: data.purchases, lines: ["credits_purchased"] },
                    { title: "Job Costs", data: data.jobs_costs, lines: ["jobs_costs"] },
                ].map((chart, index) => (
                    <div key={index}>
                        <h3 className="text-base md:text-lg font-semibold text-gray-800 mb-4">{chart.title}</h3>
                        <div className="h-48 md:h-64 bg-white p-4 rounded-lg shadow-md">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    data={chart.data}
                                    margin={{ top: 10, right: 30, left: 20, bottom: 0 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="period" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    {chart.lines.map((lineKey, idx) => (
                                        <Line
                                            key={idx}
                                            type="monotone"
                                            dataKey={lineKey}
                                            stroke={idx % 2 === 0 ? "#2563eb" : "#60a5fa"}
                                            strokeWidth={2}
                                        />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UserStatistics;
