import data from './wordlist.json';

interface Wordlist {
    nouns: string[];
    adjectives: string[];
}

function getRandomIntInclusive(min: number, max: number) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);

    // Generates a random integer in the range [min, max]
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
}

export function getRandomName(wordlist: Wordlist) {
    const firstAdjectiveIndex = getRandomIntInclusive(
        0,
        wordlist.adjectives.length - 1
    );
    let secondAdjectiveIndex: number;

    do {
        secondAdjectiveIndex = getRandomIntInclusive(
            0,
            wordlist.adjectives.length - 1
        );
    } while (secondAdjectiveIndex === firstAdjectiveIndex);

    const nounIndex = getRandomIntInclusive(0, wordlist.nouns.length);

    // Combine the adjectives and noun to form a name
    const firstAdjective = wordlist.adjectives[firstAdjectiveIndex];
    const secondAdjective = wordlist.adjectives[secondAdjectiveIndex];
    const noun = wordlist.nouns[nounIndex];
    const name = `${firstAdjective}-${secondAdjective}-${noun}`;
    return name;
}

export const wordlist: Wordlist = {
    nouns: data.nouns,
    adjectives: data.adjectives,
};
