import { Form, Link, useLoaderData } from 'react-router-dom';
import * as Popover from '@radix-ui/react-popover';
import { Check, Trash2 } from 'react-feather';
import DeleteUnverifiedUsersForm from './DeleteUnverifiedUsersForm';
import DeleteUserForm from './DeleteUserForm';
import UpdateCreditsModal from './UpdateCreditsModal';
import Pagination from '../../../base/Pagination';
import { formatDate } from '../../../../utils';

interface User {
    credits: number;
    email: string;
    email_verified: boolean;
    id: string;
    created_ts: number;
    username: string;
}

interface UserData {
    users: User[];
    current_page: number;
    page_size: number;
    search_results_count: number;
    total_pages: number;
    search_query: string;
}

function Home() {
    const data = useLoaderData() as UserData;

    const startIndex = (data.current_page - 1) * data.page_size + 1;
    const endIndex = Math.min(
        startIndex + data.page_size - 1,
        data.search_results_count
    );

    return (
        <>
            <h1 className="font-bold text-3xl pb-2">Users</h1>
            {data.search_results_count === 0 ? (
                <p>No users found</p>
            ) : data.search_query ? (
                <p>
                    Showing {startIndex}-{endIndex} of{' '}
                    {data.search_results_count} results for "{data.search_query}
                    "
                </p>
            ) : (
                <p>
                    Showing {startIndex}-{endIndex} of{' '}
                    {data.search_results_count} users
                </p>
            )}
            <Form className="relative">
                <input
                    type="text"
                    name="search_query"
                    className="w-full px-4 py-2 text-sm border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:placeholder-gray-400"
                    placeholder="Search by username or email..."
                />
            </Form>
            <div className="flex flex-wrap-reverse gap-8 justify-between">
                <Pagination
                    style="grayscale"
                    currentPage={data.current_page}
                    totalPages={data.total_pages}
                />
                <DeleteUnverifiedUsersForm />
            </div>
            <table className="w-full table-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden text-center">
                <thead className="bg-gray-100 dark:bg-gray-700 border-gray-200 dark:border-gray-600 border-b-2 z-20">
                    <tr>
                        <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                            Email
                        </th>
                        <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                            Username
                        </th>
                        <th className="py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                            Registered on
                        </th>
                        <th className="py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                            Verified
                        </th>
                        <th className="py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                            Credits
                        </th>
                        <th className="py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                            Give/taketh
                        </th>
                        <th className="py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                            Delete
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                    {data.users.map((user, index) => (
                        <tr
                            key={user.id}
                            className={
                                index % 2 === 0
                                    ? 'bg-white dark:bg-gray-800'
                                    : 'bg-gray-50 dark:bg-gray-700'
                            }
                        >
                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                {user.email}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                {user.username !== null ? (
                                    <Link
                                        to={`/admin/users/${user.username}`}
                                        className="underline underline-offset-4 hover:no-underline"
                                    >
                                        {user.username}
                                    </Link>
                                ) : (
                                    'None'
                                )}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                {formatDate(user.created_ts, 'MMMM D, YYYY')}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                {user.email_verified && (
                                    <Check
                                        strokeWidth={3}
                                        className="inline w-5 h-5 text-green-400"
                                    />
                                )}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                {user.credits}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                <UpdateCreditsModal email={user.email}>
                                    <button className="underline underline-offset-4 hover:no-underline">
                                        Gift...
                                    </button>
                                </UpdateCreditsModal>
                            </td>
                            <td className="relative">
                                <Popover.Root>
                                    <Popover.Trigger asChild>
                                        <button>
                                            <Trash2
                                                className="inline w-4 h-4 ml-1 hover:text-red-700"
                                                strokeWidth="2"
                                            />
                                        </button>
                                    </Popover.Trigger>
                                    <Popover.Portal>
                                        <Popover.Content
                                            className="w-48 bg-white dark:bg-gray-700 rounded-lg shadow"
                                            sideOffset={5}
                                        >
                                            <div className="flex flex-col gap-y-4 p-4 text-center">
                                                Permanently delete this user?
                                                <div className="flex gap-x-2">
                                                    <Popover.Close asChild>
                                                        <button className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:shadow-outline">
                                                            Cancel
                                                        </button>
                                                    </Popover.Close>
                                                    <DeleteUserForm
                                                        email={user.email}
                                                    />
                                                </div>
                                            </div>
                                        </Popover.Content>
                                    </Popover.Portal>
                                </Popover.Root>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

export default Home;
