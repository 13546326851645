import { Link } from 'react-router-dom';
import { ArrowRight, ExternalLink } from 'react-feather';
import Callout from '../../Callout';
import CodeBlock from '../../CodeBlock';
import InlineCode from '../../InlineCode';

function EDSLInstallation() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">Installing EDSL</h1>
                <p>
                    <span className="italic">
                        Expected Parrot Domain-Specific Language
                    </span>{' '}
                    (EDSL) is an open-source Python package.
                </p>
                <p>
                    EDSL is available at{' '}
                    <a
                        href="https://pypi.org/project/edsl/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        PyPI
                    </a>{' '}
                    and{' '}
                    <a
                        href="https://github.com/expectedparrot/edsl"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        GitHub
                    </a>{' '}
                    and is compatible with Python versions 3.9-3.12.
                </p>
                <br />
                <Callout title="Tip">
                    See the{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/installation.html"
                        target="_blank"
                        className={linkStyles}
                    >
                        EDSL Docs
                        <ExternalLink
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </a>{' '}
                    for troubleshooting tips, starter tutorials and in-depth
                    documentation.
                </Callout>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">Quickstart Installation</h2>
                <p>To install the latest version of EDSL through pip:</p>
                <CodeBlock>pip install edsl</CodeBlock>
                <p>To update to the latest version of EDSL:</p>
                <CodeBlock>pip install --upgrade edsl</CodeBlock>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">Advanced Installation</h2>
                <p>
                    The Quickstart Installation steps will install EDSL globally
                    on your system. Sometimes, you may face problems with
                    conflicts between EDSL and other libraries. To avoid such
                    problems, we recommend that you use a virtual environment.
                </p>
                <p>Open your terminal and run the following command:</p>
                <CodeBlock>python3 -m venv myenv</CodeBlock>
                <p>
                    This will create a folder called{' '}
                    <InlineCode>myenv</InlineCode>. Next, activate your virtual
                    environment:
                </p>
                <p> MacOS and Linux:</p>
                <CodeBlock>source myenv/bin/activate</CodeBlock>
                <p> Windows:</p>
                <CodeBlock>myenv\Scripts\activate</CodeBlock>
                <p>
                    You can now install EDSL through pip within your virtual
                    environment:
                </p>
                <CodeBlock>pip install edsl</CodeBlock>
                <p>
                    You will have access to EDSL while your virtual environment
                    is activated.
                </p>
                <p>
                    You can deactivate the virtual environment at any time by
                    running:
                </p>
                <CodeBlock>deactivate</CodeBlock>
                <p>
                    To delete the virtual environment, simply delete the{' '}
                    <InlineCode>myenv</InlineCode> folder.
                </p>
            </div>
        </>
    );
}

export default EDSLInstallation;
