import { Link } from 'react-router-dom';
import InlineCode from '../../InlineCode';
import CodeBlock from '../../CodeBlock';

function EDSLAPIKeys() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const edslSettingsImageURL = new URL(
        '../../../../../public/assets/getting-started/edsl-settings.png',
        import.meta.url
    ).pathname;

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">API keys</h1>
                <p>
                    API keys are required to access large language models (LLMs)
                    such as OpenAI's GPTs, Google's Gemini, Anthropic's Claude
                    and{' '}
                    <Link
                        to="/getting-started/coop-pricing"
                        className={linkStyles}
                    >
                        many others
                    </Link>
                    .
                </p>
                <br />
                <p>
                    EDSL allows you to access LLMs using either (i) your own
                    keys for individual service providers or (ii) an Expected
                    Parrot key that provides access to all available models at
                    once. Your Expected Parrot key also lets you run surveys and
                    store results at the Expected Parrot server and access other{' '}
                    <Link to="/content/explore" className={linkStyles}>
                        special features for conducting research at the Coop
                    </Link>
                    .
                </p>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl">
                    How to store your API keys
                </h2>
            </div>
            <div className="space-y-2">
                <h3 className="font-bold text-2xl">
                    Add your keys to a <InlineCode>.env</InlineCode> file
                </h3>
                <p>
                    In your EDSL directory, create a file named ".env" and
                    populate it with any keys that you want to use in the
                    following format (see special instructions for{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/colab_setup.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        using EDSL in Colab
                    </a>
                    ):
                </p>
                <br />
                <CodeBlock>
                    <span className="">EXPECTED_PARROT_API_KEY</span> =
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <br />
                    <span className="">ANTHROPIC_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span className="">DEEP_INFRA_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span className="">GOOGLE_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span className="">GROQ_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span className="">MISTRAL_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span className="">OPENAI_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span className="">TOGETHER_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                </CodeBlock>
            </div>
            <div className="space-y-2">
                <p>
                    Your Expected Parrot API key can be copied from the{' '}
                    <Link to="/home/api" className={linkStyles}>
                        Settings
                    </Link>{' '}
                    page of your{' '}
                    <Link to="/login" className={linkStyles}>
                        Coop
                    </Link>{' '}
                    account where you can also reset it at any time. Storing it
                    in your <span className="italic">.env</span> file allows you
                    to post and interact with content at Coop. It also allows
                    you to run surveys with{' '}
                    <Link
                        to="/getting-started/coop-pricing"
                        className={linkStyles}
                    >
                        any available language models
                    </Link>{' '}
                    at the Expected Parrot server when{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/remote_inference.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        remote inference
                    </a>{' '}
                    is turned on. See instructions below.
                </p>
                <br />
                <p>
                    Storing your own keys for service providers (Open AI,
                    Anthropic, Google, etc.) allows you to run surveys on your
                    own machine. You can post content from your own machine to
                    Coop if your Expected Parrot API key is also stored.
                </p>
                <br />
                <p>
                    See special instructions for{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/colab_setup.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        using EDSL in Colab.
                    </a>
                </p>
            </div>
            <div className="space-y-2">
                <h3 className="font-bold text-2xl">
                    Activate remote inference to run surveys at the Expected
                    Parrot server
                </h3>
                <p>
                    Navigate to the{' '}
                    <Link to="/home/api" className={linkStyles}>
                        Settings
                    </Link>{' '}
                    page of your Coop account and turn on "remote inference".
                    This will allow you to use your Expected Parrot key to run
                    surveys and store results at the Expected Parrot server. You
                    can also activate remote caching and logging to
                    automatically cache results at the Expected Parrot server.
                </p>
                <br />
                <img
                    src={edslSettingsImageURL}
                    alt="Screenshot of the EDSL settings page. Remote cache, inference, and logging are all turned on"
                    className="max-w-xs"
                />
            </div>
        </>
    );
}

export default EDSLAPIKeys;
