import { NavLink, useNavigate } from 'react-router-dom';
import {
    Home,
    Power,
    Tool,
    User,
    Grid,
    Folder,
    Edit3,
    Settings,
    Database,
    Cpu,
    DollarSign,
    CreditCard,
} from 'react-feather';
import SidebarContainer from './SidebarContainer';
import { logout } from '../../helpers/auth.helpers';
import useAuth from '../../hooks/useAuth';

function WebAppSidebar() {
    const { isBetaUser } = useAuth();
    const navigate = useNavigate();

    function handleLogout() {
        logout().then((responseMsg: string) => {
            if (responseMsg === 'Logout successful!') {
                navigate('/login');
            } else {
                console.log('Logout unsuccessful.');
            }
        });
    }

    const activeNavLinkStyles =
        'w-full inline-flex items-center p-2 text-blue-600 dark:text-primary-dark-text-accent font-bold';
    const inactiveNavLinkStyles =
        'w-full inline-flex items-center p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-50/10 dark:text-primary-dark-text-accent';

    return (
        <SidebarContainer>
            <nav className="space-y-2 text-blue-400 text-sm">
                <div className="-space-y-2 pb-2">
                    <NavLink
                        to="/home"
                        className={({ isActive }) =>
                            isActive
                                ? `${activeNavLinkStyles}`
                                : `${inactiveNavLinkStyles}`
                        }
                        end={true}
                    >
                        <Home className="inline w-4 h-4 mr-3" strokeWidth="2" />
                        Home
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold text-xs">
                        Content
                    </div>
                    <NavLink
                        to="/content"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <Grid className="inline w-4 h-4 mr-3" strokeWidth="2" />
                        View
                    </NavLink>
                    {isBetaUser && (
                        <NavLink
                            to="/create"
                            className={({ isActive }) =>
                                isActive
                                    ? `flex items-center p-2 ${activeNavLinkStyles}`
                                    : `flex items-center p-2 ${inactiveNavLinkStyles}`
                            }
                        >
                            <Tool
                                className="inline w-4 h-4 mr-3"
                                strokeWidth="2"
                            />
                            Create
                            <span className="inline-flex items-center ml-3 rounded-md bg-green-50 dark:bg-green-600/30 dark:text-primary-dark-text px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                Beta
                            </span>
                        </NavLink>
                    )}
                    {isBetaUser && (
                        <NavLink
                            to="/home/projects"
                            className={({ isActive }) =>
                                isActive
                                    ? `flex p-2 ${activeNavLinkStyles}`
                                    : `flex p-2 ${inactiveNavLinkStyles}`
                            }
                        >
                            <Folder
                                className="inline w-4 h-4 mr-3"
                                strokeWidth="2"
                            />
                            Projects
                            <span className="inline-flex items-center ml-3 rounded-md bg-green-50 dark:bg-green-600/30 dark:text-primary-dark-text px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                Beta
                            </span>
                        </NavLink>
                    )}
                    {isBetaUser && (
                        <NavLink
                            to="/home/respond"
                            className={({ isActive }) =>
                                isActive
                                    ? `flex p-2 ${activeNavLinkStyles}`
                                    : `flex p-2 ${inactiveNavLinkStyles}`
                            }
                        >
                            <Edit3
                                className="inline w-4 h-4 mr-3"
                                strokeWidth="2"
                            />
                            Respond
                            <span className="inline-flex items-center ml-3 rounded-md bg-green-50 dark:bg-green-600/30 dark:text-primary-dark-text px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                Beta
                            </span>
                        </NavLink>
                    )}
                </div>
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold text-xs">
                        API
                    </div>
                    <NavLink
                        to="/home/api"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <Settings
                            className="inline w-4 h-4 mr-3"
                            strokeWidth="2"
                        />
                        Settings
                    </NavLink>
                    <NavLink
                        to="/home/remote-cache"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <Database
                            className="inline w-4 h-4 mr-3"
                            strokeWidth="2"
                        />
                        Remote Cache
                    </NavLink>
                    <NavLink
                        to="/home/remote-inference"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <Cpu className="inline w-4 h-4 mr-3" strokeWidth="2" />
                        Remote Inference
                    </NavLink>
                    <NavLink
                        to="/home/pricing"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <DollarSign
                            className="inline w-4 h-4 mr-3"
                            strokeWidth="2"
                        />
                        Pricing
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold text-xs">
                        Account
                    </div>
                    <NavLink
                        to="/home/purchases"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <CreditCard
                            className="inline w-4 h-4 mr-3"
                            strokeWidth="2"
                        />
                        Credits
                    </NavLink>
                    <NavLink
                        to="/home/profile"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        <User className="inline w-4 h-4 mr-3" strokeWidth="2" />
                        Profile
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <button
                        onClick={handleLogout}
                        type="button"
                        className="w-full inline-flex items-center p-2 text-red-700 hover:text-red-900 hover:font-bold dark:text-amber-400 dark:hover:text-amber-500"
                    >
                        <Power
                            className="inline w-4 h-4 mr-3"
                            strokeWidth="2.5"
                        />
                        Log Out
                    </button>
                </div>
            </nav>
        </SidebarContainer>
    );
}

export default WebAppSidebar;
