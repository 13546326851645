import { Link } from 'react-router-dom';
import { ArrowRight, ExternalLink } from 'react-feather';

function GettingStartedIndex() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <div className="space-y-10">
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">
                    Get started conducting research with AI.
                </h1>
                <p>
                    Expected Parrot tools make it easy to run surveys,
                    experiments, and many different research tasks with AI
                    agents and large language models.{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        Learn more.
                    </a>
                </p>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl pb-2">How it works</h2>
                <p>
                    <span className="italic">
                        Expected Parrot Domain-Specific Language
                    </span>{' '}
                    (
                    <a
                        href="https://pypi.org/project/edsl/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        EDSL
                    </a>
                    ) is an{' '}
                    <a
                        href="https://github.com/expectedparrot/edsl"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        open-source Python package
                    </a>{' '}
                    for constructing questions and designing AI agents to answer
                    them, using language models of your choice to generate
                    responses in readily analyzable datasets. Learn more about
                    this method and see examples for use cases in our{' '}
                    <a
                        href="https://docs.expectedparrot.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        documentation
                    </a>{' '}
                    page. To get started:
                </p>
            </div>
            <div className="space-y-2">
                <h3 className="font-bold text-2xl">
                    1. Install the EDSL package.
                </h3>
                <p>
                    See{' '}
                    <Link
                        to="/getting-started/edsl-installation"
                        className={linkStyles}
                    >
                        installation
                    </Link>{' '}
                    instructions.
                </p>
            </div>
            <div className="space-y-2">
                <h3 className="font-bold text-2xl">
                    2. Store API keys for language models.
                </h3>
                <p>
                    Get an Expected Parrot key to run surveys with any available
                    models at the Expected Parrot server, or store your own
                    keys. See{' '}
                    <Link
                        to="/getting-started/edsl-api-keys"
                        className={linkStyles}
                    >
                        how to store keys
                    </Link>
                    .
                </p>
            </div>
            <div className="space-y-2">
                <h3 className="font-bold text-2xl">
                    3. Create and run a survey.
                </h3>
                <p>
                    Explore a{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        starter tutorial
                    </a>{' '}
                    and other{' '}
                    <a
                        href="https://docs.expectedparrot.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        examples and templates
                    </a>{' '}
                    for many different use cases.
                </p>
            </div>
            <div className="space-y-2">
                <h3 className="font-bold text-2xl">4. Collaborate on Coop.</h3>
                <p>
                    A platform for creating, storing, and sharing AI-based
                    research.{' '}
                    <Link to="/login" className={linkStyles}>
                        Log in or sign up
                        <ArrowRight
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </Link>
                </p>
            </div>
        </div>
    );
}

export default GettingStartedIndex;
