import * as Popover from '@radix-ui/react-popover';
import { Info } from 'react-feather';
import { Results } from '../../../../../types';

function CreditsTooltip() {
    return (
        <Popover.Root>
            <Popover.Trigger asChild>
                <button
                    type="button"
                    className="inline-flex items-center px-2 py-2 gap-x-2 text-sm font-medium rounded-lg hover:bg-gray-100 dark:hover:bg-gray-300/10"
                    aria-label="More information about credits"
                >
                    <Info className="w-4 h-4" />
                </button>
            </Popover.Trigger>

            <Popover.Portal>
                <Popover.Content
                    className="w-64 p-4 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-100/20 rounded-lg shadow"
                    sideOffset={5}
                >
                    An estimate of the number of credits needed to generate
                    these results. Cached responses are evaluated at their
                    original cost.
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}

function ResultsAbout({ results }: { results: Results }) {
    return (
        <div className="relative overflow-x-auto sm:rounded-lg">
            <table className="table-fixed w-full text-md text-left rtl:text-right dark:text-primary-dark-text">
                <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 dark:text-primary-dark-text bg-white dark:bg-primary-dark-bg">
                    About these results
                </caption>
                <thead className="text-s text-gray-700 dark:text-primary-dark-text bg-sky-100 dark:bg-sky-700/50">
                    <tr>
                        <th className="w-1/4 px-6 py-3">Category</th>
                        <th className="w-3/4 px-6 py-3">Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white dark:bg-primary-dark-bg hover:bg-gray-50 dark:hover:bg-gray-50/5 border-b dark:border-gray-100/20">
                        <td className="flex items-center gap-1 px-6 py-4">
                            Credits
                            <CreditsTooltip />
                        </td>
                        <td className="px-6 py-4">
                            {results.data.about['Credits']}
                        </td>
                    </tr>
                    <tr className="bg-white dark:bg-primary-dark-bg hover:bg-gray-50 dark:hover:bg-gray-50/5 border-b dark:border-gray-100/20">
                        <td className="px-6 py-4">Questions</td>
                        <td className="px-6 py-4">
                            {results.data.about['Questions']}
                        </td>
                    </tr>
                    <tr className="bg-white dark:bg-primary-dark-bg hover:bg-gray-50 dark:hover:bg-gray-50/5 border-b dark:border-gray-100/20">
                        <td className="px-6 py-4">Observations</td>
                        <td className="px-6 py-4">
                            {results.data.about['Observations']}
                        </td>
                    </tr>
                    <tr className="bg-white dark:bg-primary-dark-bg hover:bg-gray-50 dark:hover:bg-gray-50/5 border-b dark:border-gray-100/20">
                        <td className="px-6 py-4">Models</td>
                        <td className="px-6 py-4">
                            {results.data.about['Models']}
                        </td>
                    </tr>
                    <tr className="bg-white dark:bg-primary-dark-bg hover:bg-gray-50 dark:hover:bg-gray-50/5 border-b dark:border-gray-100/20">
                        <td className="px-6 py-4">Agents</td>
                        <td className="px-6 py-4">
                            {results.data.about['Agents']}
                        </td>
                    </tr>
                    <tr className="bg-white dark:bg-primary-dark-bg hover:bg-gray-50 dark:hover:bg-gray-50/5 border-b dark:border-gray-100/20">
                        <td className="px-6 py-4">Scenarios</td>
                        <td className="px-6 py-4">
                            {results.data.about['Scenarios']}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default ResultsAbout;
