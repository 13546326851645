import { Form, Link, useLoaderData } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import { formatDate } from '../../../../utils';

interface Purchase {
    created_ts: number;
    provider: string;
    type: string;
    amount: number;
    quantity: number;
    invoice_url: string;
}

interface Gift {
    created_ts: number;
    is_admin_gift: boolean;
    credits_gifted: number;
    note: string | null;
    sender_username: string | null;
    recipient_username: string | null;
    user_is_sender: boolean;
    user_is_recipient: boolean;
}

interface PurchaseData {
    is_authenticated: boolean;
    purchases: Purchase[];
    gifts: Gift[];
    api_credits: number;
}

function getSenderUsername(gift: Gift) {
    if (gift.is_admin_gift === true) {
        return 'EP Admin';
    } else if (gift.sender_username === null) {
        return 'User not found';
    } else if (gift.user_is_sender) {
        return 'You';
    } else {
        return gift.sender_username;
    }
}

function getRecipientUsername(gift: Gift) {
    if (gift.recipient_username === null) {
        return 'User not found';
    } else if (gift.user_is_recipient) {
        return 'You';
    } else {
        return gift.recipient_username;
    }
}

function Purchases() {
    const purchaseData = useLoaderData() as PurchaseData;

    function formatPrice(cents: number) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return formatter.format(cents / 100);
    }

    function formatCreditBalance(balance: number) {
        const formatter = new Intl.NumberFormat('en-US');
        return formatter.format(balance);
    }

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Credits</h1>
                <p className="text-lg">
                    Purchase credits to run surveys remotely on the Expected
                    Parrot server.
                </p>
                <p className="text-lg">
                    Learn more about{' '}
                    <Link
                        to="https://docs.expectedparrot.com/en/latest/remote_inference.html"
                        target="_blank"
                        className="text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                    >
                        remote inference
                    </Link>{' '}
                    and how{' '}
                    <Link
                        to="https://docs.expectedparrot.com/en/latest/credits.html"
                        target="_blank"
                        className="text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                    >
                        credits
                    </Link>{' '}
                    work.
                </p>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">Balance</h2>

                <div className="flex">
                    <p className="text-lg border border-gray-300 p-2 rounded-lg">
                        <span className="font-bold">
                            {formatCreditBalance(purchaseData.api_credits)}
                        </span>{' '}
                        credits{' '}
                    </p>
                </div>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">Purchase</h2>
                <p className="text-lg">
                    All payments are processed through Stripe. You may be
                    charged payment processing fees.
                </p>
                <p className="text-lg">
                    $1.00 = 100 credits. You must purchase at least 100 credits
                    in one transaction.
                </p>
                <Form method="post" className="flex flex-col">
                    <div className="flex">
                        <input
                            type="number"
                            name="credit_amount"
                            placeholder="Number of credits"
                            min="100"
                            max="99999999"
                            required
                            className="block p-2.5 grow max-w-56 bg-white focus:outline-none border rounded-md border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                        />
                    </div>

                    <div className="mt-4 max-w-80">
                        <label
                            htmlFor="extra-invoice-notes"
                            className="block text-md font-bold"
                        >
                            Invoice notes (optional)
                        </label>
                        <textarea
                            id="extra-invoice-notes"
                            name="extra_invoice_notes"
                            rows={2}
                            className="block resize-none w-full mt-1 p-2 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                        />
                    </div>
                    <button
                        type="submit"
                        className="px-3 w-24 mt-4 py-2.5 bg-green-600 hover:bg-green-700 rounded-md text-white font-semibold"
                    >
                        Purchase
                    </button>
                </Form>
            </div>
            <Tabs.Root defaultValue="purchases">
                <Tabs.List
                    className="flex gap-x-2 mb-4 border-b border-gray-200 dark:border-gray-700"
                    aria-label="Switch tabs"
                >
                    <Tabs.Trigger
                        className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="purchases"
                    >
                        Purchases
                    </Tabs.Trigger>
                    <Tabs.Trigger
                        className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="gifting"
                    >
                        Gifts
                    </Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content value="purchases">
                    <div className="space-y-2">
                        <table className="w-full table-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden text-center">
                            <thead className="bg-gray-100 dark:bg-gray-700 border-gray-200 dark:border-gray-600 border-b-2 z-20">
                                <tr>
                                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                        Date
                                    </th>
                                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                        Type
                                    </th>
                                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                        Amount
                                    </th>
                                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                        Quantity
                                    </th>
                                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                        Provider
                                    </th>
                                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                        Invoice
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                                {purchaseData.purchases.map(
                                    (purchase, index) => (
                                        <tr
                                            key={index}
                                            className={`${
                                                index % 2 === 0
                                                    ? 'bg-white dark:bg-gray-800'
                                                    : 'bg-gray-50 dark:bg-gray-700'
                                            }`}
                                        >
                                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                                {formatDate(
                                                    purchase.created_ts,
                                                    'MMMM D, YYYY'
                                                )}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                                {purchase.type}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                                {formatPrice(purchase.amount)}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                                {purchase.quantity}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                                {purchase.provider}
                                            </td>
                                            <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                                <a
                                                    href={purchase.invoice_url}
                                                    target="_blank"
                                                    className="text-blue-500 hover:text-blue-600 dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                                                >
                                                    View
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </Tabs.Content>
                <Tabs.Content value="gifting">
                    <div className="space-y-2">
                        <table className="w-full table-auto bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-hidden text-center">
                            <thead className="bg-gray-100 dark:bg-gray-700 border-gray-200 dark:border-gray-600 border-b-2 z-20">
                                <tr>
                                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                        Date
                                    </th>
                                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                        Sender
                                    </th>
                                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                        Recipient
                                    </th>
                                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                        Note
                                    </th>
                                    <th className="px-4 py-2 font-bold text-blue-500 dark:text-primary-dark-text-accent">
                                        Credits Gifted
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                                {purchaseData.gifts.map((gift, index) => (
                                    <tr
                                        key={index}
                                        className={`${
                                            index % 2 === 0
                                                ? 'bg-white dark:bg-gray-800'
                                                : 'bg-gray-50 dark:bg-gray-700'
                                        }`}
                                    >
                                        <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                            {formatDate(
                                                gift.created_ts,
                                                'MMMM D, YYYY'
                                            )}
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                            {getSenderUsername(gift)}
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                            {getRecipientUsername(gift)}
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                            {gift.note}
                                        </td>
                                        <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                            {gift.credits_gifted}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Tabs.Content>
            </Tabs.Root>
        </>
    );
}

export default Purchases;
