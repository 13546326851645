import * as Dialog from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { useFetcher } from 'react-router-dom';
import { X } from 'react-feather';
import ActionAlert from '../../../../../base/design-system/ActionAlert';

function DeleteModal({
    projectId,
    children,
}: {
    projectId: string;
    children: React.ReactNode;
}) {
    const fetcher = useFetcher();

    function getDeleteFeedback() {
        if (!fetcher.data) {
            return;
        }
        return (
            <p className="text-sm text-red-600 dark:text-red-500">
                {fetcher.data}
            </p>
        );
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content
                    className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show"
                    aria-describedby={undefined}
                >
                    <div className="flex flex-col items-center text-sm text-center py-4 md:py-5">
                        <Dialog.Title asChild>
                            <h3 className="mb-5 text-lg font-normal">
                                Are you sure you want to delete this project?
                            </h3>
                        </Dialog.Title>
                        <div className="flex">
                            <Dialog.Close asChild>
                                <button
                                    type="button"
                                    className="py-2.5 px-5 me-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                >
                                    No, cancel
                                </button>
                            </Dialog.Close>
                            <fetcher.Form method="post">
                                <input
                                    type="hidden"
                                    name="project_uuid"
                                    value={projectId}
                                />
                                <button
                                    type="submit"
                                    name="intent"
                                    value="delete_project"
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                                >
                                    Yes, I'm sure
                                </button>
                            </fetcher.Form>
                        </div>
                        {getDeleteFeedback()}
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

function EditModal({
    projectId,
    projectName,
    children,
}: {
    projectId: string;
    projectName: string;
    children: React.ReactNode;
}) {
    const fetcher = useFetcher();

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Edit
                        </h3>
                    </Dialog.Title>
                    <VisuallyHidden.Root>
                        <Dialog.Description>
                            Make changes to your project name.
                        </Dialog.Description>
                    </VisuallyHidden.Root>
                    <div className="text-sm py-4 md:py-5">
                        <fetcher.Form method="post">
                            <div>
                                <label
                                    htmlFor={`edit-project-name-input-${projectId}`}
                                    className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                                >
                                    Project name
                                </label>
                                <input
                                    id={`edit-project-name-input-${projectId}`}
                                    name="project_name"
                                    className="block resize-none p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    defaultValue={projectName}
                                />
                                <input
                                    type="hidden"
                                    name="project_uuid"
                                    value={projectId}
                                />
                            </div>
                            <button
                                type="submit"
                                name="intent"
                                value="edit_project"
                                className="w-full mt-3 mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Save
                            </button>
                        </fetcher.Form>
                        <ActionAlert response={fetcher.data} />
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export { DeleteModal, EditModal };
