import { useLoaderData } from 'react-router-dom';
import { Camera } from 'react-feather';
import * as Avatar from '@radix-ui/react-avatar';
import BetaToggle from './BetaToggle';
import ChangeProfilePhotoModal from './ChangeProfilePhotoModal';
import DeleteAccountModal from './DeleteAccountModal';
import EditUsernameForm from './EditUsernameForm';
import { formatDate } from '../../../../utils';

interface ProfileData {
    is_authenticated: boolean;
    is_beta_user: boolean;
    email: string;
    created_ts: number;
    username: string;
    base_64_profile_photo: string;
}

function Profile() {
    const profileData = useLoaderData() as ProfileData;

    const formattedDate = formatDate(profileData.created_ts, 'MMMM D, YYYY');

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Profile</h1>
            </div>
            <div className="space-y-2 max-w-3xl">
                <h2 className="font-bold text-2xl">Information</h2>
                <div className="w-full pt-4 flex flex-col md:flex-row-reverse md:gap-12">
                    <div className="mb-6">
                        <div className="relative inline-block">
                            <Avatar.Root className="inline-flex size-40 select-none items-center justify-center overflow-hidden rounded-full bg-green-100 align-middle">
                                <Avatar.Image
                                    className="size-full rounded-full object-cover"
                                    src={profileData.base_64_profile_photo}
                                    alt="Profile photo"
                                />
                                <Avatar.Fallback
                                    className="flex size-full items-center justify-center bg-green-100 text-5xl leading-1 font-medium text-green-800"
                                    delayMs={600}
                                >
                                    {profileData.username
                                        ? profileData.username
                                              .slice(0, 2)
                                              .toUpperCase()
                                        : 'AN'}
                                </Avatar.Fallback>
                            </Avatar.Root>
                            <ChangeProfilePhotoModal
                                username={profileData.username}
                                profilePhoto={profileData.base_64_profile_photo}
                            >
                                <button
                                    type="button"
                                    className="absolute -bottom-3 right-0 rounded-md p-3 bg-gray-200 hover:bg-gray-300 text-black"
                                    aria-label="Change profile photo"
                                >
                                    <Camera className="w-5 h-5" />
                                </button>
                            </ChangeProfilePhotoModal>
                        </div>
                    </div>
                    <div className="flex-1 space-y-4">
                        <div className="flex flex-col">
                            <div className="md:w-1/3 pr-4">
                                <span className="font-bold py-2">
                                    Account Created
                                </span>
                            </div>
                            <div className="grow py-2">{formattedDate}</div>
                        </div>
                        <div className="flex flex-col">
                            <div className="md:w-1/3 pr-4">
                                <span className="font-bold py-2">Email</span>
                            </div>
                            <div className="grow py-2">{profileData.email}</div>
                        </div>
                        <EditUsernameForm
                            currentUsername={profileData.username}
                        />
                    </div>
                </div>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">Beta</h2>
                <p>Get access to beta features.</p>
                <BetaToggle defaultValue={profileData.is_beta_user} />
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-2xl">Danger Zone</h2>
                <p>This action is irreversible!</p>
                <DeleteAccountModal>
                    <button
                        type="submit"
                        name="intent"
                        value="delete_account"
                        className="mt-2 bg-red-600 hover:bg-red-700 border-2 border-red-600 hover:border-red-700 rounded-md px-3 py-2 text-white font-semibold"
                    >
                        Delete account
                    </button>
                </DeleteAccountModal>
            </div>
        </>
    );
}

export default Profile;
