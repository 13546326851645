import { useState } from 'react';
import DataTableColumnFilter from './DataTableColumnFilter';
import Toggle from '../../../../base/Toggle';

function DataTable({
    columns,
    records,
}: {
    columns: object;
    records: object[];
}) {
    const [isWrapped, setIsWrapped] = useState(false);
    const [columnsToShow, setColumnsToShow] = useState(() => {
        const lst = Object.entries(columns)
            .map(([name, isRequired], index) => {
                return { id: index, name: name, isRequired: isRequired };
            })
            // Arrange columns in alphabetical order
            .sort((a, b) => {
                const colA = a.name.toUpperCase();
                const colB = b.name.toUpperCase();
                if (colA < colB) {
                    return -1;
                }
                if (colA > colB) {
                    return 1;
                }
                return 0;
            });
        return lst;
    });

    // Pagination logic
    const itemsPerPage = 500;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(records.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentRecords = records.slice(startIndex, endIndex);

    const recordData = currentRecords.map((record, index) => {
        const fields = Object.entries(record)
            .map(([colName, value], index) => {
                const column = columnsToShow.find(
                    (col) => col.name === colName
                );
                const isRequired = column.isRequired === true;
                const formattedValue =
                    typeof value === 'string' ? value : JSON.stringify(value);
                return {
                    id: index,
                    colName: colName,
                    value: formattedValue,
                    isRequired: isRequired,
                };
            })
            // Arrange fields in alphabetical order by column name
            .sort((a, b) => {
                const colA = a.colName.toUpperCase();
                const colB = b.colName.toUpperCase();
                if (colA < colB) {
                    return -1;
                }
                if (colA > colB) {
                    return 1;
                }
                return 0;
            });
        return { id: index, fields: fields };
    });

    function handleChangeColumnVisibility(
        columnId: number,
        isVisible: boolean
    ) {
        const nextColumnsToShow = [...columnsToShow];
        const colIndex = nextColumnsToShow.findIndex(
            (col) => col.id == columnId
        );
        nextColumnsToShow[colIndex] = {
            ...nextColumnsToShow[colIndex],
            isRequired: isVisible,
        };
        setColumnsToShow(nextColumnsToShow);
    }

    function handleToggleWrap(newIsWrapped: boolean) {
        setIsWrapped(newIsWrapped);
    }

    function handlePageChange(page: number) {
        setCurrentPage(page);
    }

    const wrappedClasses = isWrapped ? '' : 'truncate';

    return (
        <>
            <div className="flex justify-between mb-4">
                <DataTableColumnFilter
                    columns={columnsToShow}
                    changeVisibility={handleChangeColumnVisibility}
                />
                <div className="flex justify-start items-center py-1">
                    <Toggle
                        id="wrap-columns"
                        checked={isWrapped}
                        onCheckedChange={handleToggleWrap}
                        type="button"
                    />
                    <label
                        className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                        htmlFor="wrap-columns"
                    >
                        Wrap columns
                    </label>
                </div>
            </div>
            <div className="relative overflow-x-auto sm:rounded-lg mb-4">
                <table className="w-full text-sm text-left rtl:text-right">
                    <thead className="text-xs text-gray-700 dark:text-secondary-dark-text uppercase bg-gray-50 dark:bg-gray-700">
                        <tr>
                            {columnsToShow.map((col) => {
                                if (col.isRequired) {
                                    return (
                                        <th
                                            key={col.id}
                                            className={`max-w-40 break-words px-6 py-3 ${wrappedClasses}`}
                                        >
                                            {col.name}
                                        </th>
                                    );
                                } else {
                                    return (
                                        <th
                                            key={col.id}
                                            className={`hidden max-w-40 break-words px-6 py-3 ${wrappedClasses}`}
                                        >
                                            {col.name}
                                        </th>
                                    );
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {recordData.map((record) => (
                            <tr
                                key={record.id}
                                className="bg-white dark:bg-primary-dark-bg hover:bg-gray-50 dark:hover:bg-gray-50/5 border-b dark:border-gray-100/20"
                            >
                                {record.fields.map((field) => {
                                    if (field.isRequired) {
                                        return (
                                            <td
                                                key={field.id}
                                                className={`max-w-40 break-words px-6 py-4 align-top ${wrappedClasses}`}
                                            >
                                                {field.value}
                                            </td>
                                        );
                                    } else {
                                        <td
                                            key={field.id}
                                            className={`hidden max-w-40 break-words px-6 py-4 align-top ${wrappedClasses}`}
                                        >
                                            {field.value}
                                        </td>;
                                    }
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {totalPages > 1 && (
                <div className="mt-4">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            )}
        </>
    );
}

// Client-side pagination component
function Pagination({
    currentPage,
    totalPages,
    onPageChange,
}: {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}) {
    if (totalPages <= 0) return null;

    function renderPageButton(page: number) {
        return (
            <button
                key={page}
                onClick={() => onPageChange(page)}
                className={`px-3 py-1 mx-1 rounded font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 ${
                    page === currentPage
                        ? 'ring-1 ring-gray-400 dark:ring-gray-500'
                        : ''
                }`}
            >
                {page}
            </button>
        );
    }

    function renderEllipsis(key: string) {
        return (
            <span key={key} className="px-2 text-gray-600 dark:text-gray-300">
                ...
            </span>
        );
    }

    function renderPageButtons() {
        if (totalPages <= 5) {
            return Array.from({ length: totalPages }, (_, i) => i + 1).map(
                renderPageButton
            );
        }

        if (currentPage <= 3) {
            return [
                ...Array.from({ length: 3 }, (_, i) => i + 1).map(
                    renderPageButton
                ),
                renderEllipsis('start'),
                renderPageButton(totalPages),
            ];
        }

        if (currentPage >= totalPages - 2) {
            return [
                renderPageButton(1),
                renderEllipsis('end'),
                ...Array.from({ length: 3 }, (_, i) => totalPages - 2 + i).map(
                    renderPageButton
                ),
            ];
        }

        return [
            renderPageButton(1),
            renderEllipsis('start'),
            ...Array.from({ length: 3 }, (_, i) => currentPage - 1 + i).map(
                renderPageButton
            ),
            renderEllipsis('end'),
            renderPageButton(totalPages),
        ];
    }

    return (
        <div className="flex items-center justify-center">
            <button
                onClick={() => onPageChange(currentPage - 1)}
                className={`px-3 py-1 font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded ${
                    currentPage === 1 ? 'opacity-50 pointer-events-none' : ''
                }`}
            >
                ← Previous
            </button>
            {renderPageButtons()}
            <button
                onClick={() => onPageChange(currentPage + 1)}
                className={`px-3 py-1 font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded ${
                    currentPage === totalPages
                        ? 'opacity-50 pointer-events-none'
                        : ''
                }`}
            >
                Next →
            </button>
        </div>
    );
}
export default DataTable;
